import { SimpleGrid, VStack, HStack, Text, Image, Box } from '@chakra-ui/react';
import { SideNav } from './SideNav.jsx';
import { TopBar } from './TopBar.jsx';
import './App.css';

function Gallery() {
  const pictures = [];
  const images = [
    './images/sitephotos/WebsitePictures/IMG_9490.jpeg',
    './images/sitephotos/WebsitePictures/IMG_3661.jpeg',
    './images/sitephotos/WebsitePictures/IMG_2953.jpeg',
    './images/sitephotos/WebsitePictures/IMG_1241.JPG',
    './images/sitephotos/WebsitePictures/FB_IMG_1532196331114.jpg',
    './images/sitephotos/WebsitePictures/C410DE5F-C8D6-4FB1-B221-BAD129937917.jpg',
    './images/sitephotos/WebsitePictures/20180721_090940.jpg',
    './images/sitephotos/WebsitePictures/20160821_135738.jpeg',
    './images/sitephotos/WebsitePictures/20160410_164741.jpg',
    './images/sitephotos/WebsitePictures/NCM_0087.JPG',
    './images/sitephotos/WebsitePictures/IMG_5069.jpeg',
    './images/sitephotos/WebsitePictures/IMG_3630.jpeg',
    './images/sitephotos/WebsitePictures/IMG_2735.jpeg',
    './images/sitephotos/WebsitePictures/IMG_0720.jpeg',
    './images/sitephotos/WebsitePictures/D0CFDB64-37E5-4B38-BE24-C93D424BF3F5.jpg',
    './images/sitephotos/WebsitePictures/20191005_092629.jpg',
    './images/sitephotos/WebsitePictures/20171104_152323.jpg',
    './images/sitephotos/WebsitePictures/20160821_135533.jpg',
    './images/sitephotos/WebsitePictures/20160821_135533.jpg',
    './images/sitephotos/WebsitePictures/20160122_132055.jpg',
    './images/sitephotos/WebsitePictures/IMG_9494.jpeg',
    './images/sitephotos/WebsitePictures/IMG_5067.jpeg',
    './images/sitephotos/WebsitePictures/IMG_3193.jpeg',
    './images/sitephotos/WebsitePictures/IMG_1518486368823.jpg',
    './images/sitephotos/WebsitePictures/IMG_0615.jpeg',
    './images/sitephotos/WebsitePictures/C76BD0FB-BA95-4695-BB80-D8626F8A62C6.jpg',
    './images/sitephotos/WebsitePictures/20190821_170705.jpg',
    './images/sitephotos/WebsitePictures/20151212_094446.jpg',
    './images/sitephotos/WebsitePictures/20160410_164925.jpg',
    './images/sitephotos/WebsitePictures/20171104_152150.jpg'
  ];
  for (let i = 0; i < images.length; i ++) {
    pictures.push(<Image src={images[i]}/>)
  }
  return (
    <div className="App" style={{overflow: 'hidden', height: '100vh', background: '#515031', color:'#fff'}}>
        <VStack display='flex' h='100%'>
            <TopBar/>
            <div style={{display: 'flex', height:'100%', flexDirection: 'horizontal', padding: "10px 50px"}}>
              <SideNav/>
              <SimpleGrid overflow='scroll' flex={3} columns={[1, 1, 1, 2]} spacing="20px">
                  {pictures}
              </SimpleGrid>
              <Box padding='0 10px' flex={1} hideBelow='md'>
                <Text fontSize='xl' mb='10px'>
                  About
                </Text>
                <Text>
                  All my life, I've loved hunting, fishing, and cooking, so I know the blessing that is a high quality knife. 
                </Text>
                <Text pt='8px'>
                  I finally got tired of knives not built to last, so I made my own and fell in love with the process of transforming steel and wood into a tool. 
                </Text>
              </Box>
            </div>
        </VStack>
    </div>
  );
}

export { Gallery };
